import { createStore } from 'vuex'
import root from './modules/root'
import auth from './modules/auth'
import main from './modules/main'
import plugins from './plugins'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        root,
        auth,
        main
    },
    strict: debug,
    plugins
})

// if (navigator.webdriver) {
//     window.localStorage.clear();
// }

export default {
    setTokenAndId(state, data) {
        state.token = data.token;
        state.id = data.id;
    },
    setProfile(state, user) {
        state.name = user.name
        state.birthday = user.birthday
        state.gender = user.gender
        state.language = user.language.code
        state.city = user.city
        state.country = user.country.code
        state.points = user.points
        state.image = user.image
        state.mobile = user.mobile
    },
    logout(state) {
        state.name = '';
        state.birthday = ''
        state.gender = ''
        state.language = ''
        state.city = ''
        state.country = ''
        state.points = ''
        state.image = ''
        state.mobile = ''
        state.token = '';
        state.id = '';
    }
}
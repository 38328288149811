import { createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate';


const STORAGE_KEY = "besukha";

const dataState = createPersistedState({
  key: STORAGE_KEY,
  paths: ['auth']
})

export default process.env.NODE_ENV !== 'production'
  ? [createLogger(), dataState]
  : [dataState]

import api from '../../api'
import axios from 'axios'
import router from '../../router';
import { loadStore } from '../../utils/configureStorage';

let actionToast;
let actionRouter;
let actionI18n;

let myInterceptor;
export default {
    setToastAndRouter({ dispatch }, payload) {
        actionToast = payload.toast;
        actionRouter = payload.router;
        actionI18n = payload.i18n;

        let data = loadStore('besukha');
        if (data.auth && data.auth.token) {
            myInterceptor = axios.interceptors.request.use(function (config) {
                config.headers.Authorization = `Bearer ${data.auth.token}`;
                return config
            }, function (err) {
                return Promise.reject(err)
            })
            dispatch('getProfileAttempt');
        } else {
            let authPath = ['/landing', '/register', '/login', '/forgotPassword', '/register']
            if (!authPath.includes(window.location.pathname))
                actionRouter.push('/landing')
        }
        axios.interceptors.response.use((res) => {
            if (res.status == 99) {
                localStorage.clear();
                actionRouter.push('/landing')
            }
            return res
        }, (err) => {
            return err
        });
    },

    resetPasswordAttempt({ dispatch }, email) {
        dispatch('root/setLoading', { isLoading: true, label: 'notifications.updating' }, { root: true })
        api.resetPassword(email)
            .then(response => {
                let res = response.data
                if (res && res.status == 0) {
                    actionToast.show(res.description, { position: "top" })
                } else {
                    actionToast.show(res.description, { position: "top" })
                }
                dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
            })
            .catch(err => {
                console.log(err)
                actionToast.show('failed', { position: "top" })
                dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
            })
    },

    registerAttempt({ commit, dispatch }, data) {
        let loadingData = {
            isLoading: true,
            label: 'notifications.registering'
        }
        dispatch('root/setLoading', loadingData, { root: true })
        api.registerAttempt(data)
            .then((response) => {
                let res = response.data
                if (res && res.token) {
                    let tokenAndId = {
                        token: res.token,
                        id: res.id
                    }
                    commit('setTokenAndId', tokenAndId)
                    dispatch('root/setLoading', { isLoading: true, label: 'notifications.validateYourEmail' }, { root: true })
                    dispatch('getProfileAttempt', tokenAndId);
                } else {
                    if (res && res.status == 1) {
                        actionToast.show(res.description, { position: "top" })
                        dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
                    } else {
                        actionToast.show('Registration failed', { position: "top" })
                        dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
                actionToast.show('Registration failed', { position: "top" })
            })
    },

    loginAttempt({ commit, dispatch }, data) {

        let loadingData = {
            isLoading: true,
            label: "notifications.recoveringYourAccount"
        }
        dispatch('root/setLoading', loadingData, { root: true })
        api.loginAttempt(data)
            .then((response) => {
                let res = response.data
                if (res && res.token) {
                    let tokenAndId = {
                        token: res.token,
                        id: res.id
                    }
                    console.log("res.token: ", res.token);
                    // axios.defaults.headers.common['Authorization'] = `Bearer ${res.token}`
                    myInterceptor = axios.interceptors.request.use(function (config) {
                        config.headers.Authorization = `Bearer ${res.token}`;
                        return config
                    }, function (err) {
                        return Promise.reject(err)
                    })
                    commit('setTokenAndId', tokenAndId)
                    dispatch('root/setLoading', { isLoading: true, label: 'notifications.updating' }, { root: true })
                    dispatch('getProfileAttempt', tokenAndId)
                    console.log('getProfileAttempt')
                } else {
                    // if (res && res.status == 1) {
                    //     actionToast.show(res.description, { position: "top" })
                    //     dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
                    // } else {
                    actionToast.show('Credenciales incorrectas', { position: "top" })
                    dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
                    // }
                }
            })
            .catch((err) => {
                actionToast.show(err, { position: "top" })
                dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
                console.log(err)
            })
    },

    getProfileAttempt({ commit, dispatch }) {

        api.getProfileAttempt()
            .then((response) => {

                dispatch("main/getCountries", null, { root: true });
                let res = response.data;
                if (res && res.name) {
                    commit('setProfile', res);
                    actionI18n.locale = res.language.code;
                    let authPath = ['/landing', '/login', '/forgotPassword', '/register']
                    if (authPath.includes(window.location.pathname))
                        actionRouter.push('/dashboard')
                } else {
                    commit('logout')
                    localStorage.clear();
                    actionToast.show(res && res.description ?
                        res.description : "Failed to update profile photo", { position: 'top' })
                    axios.interceptors.request.eject(myInterceptor);
                    actionRouter.push('/login')
                }
                dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
            })
            .catch(err => {
                console.log(err)
                axios.interceptors.request.eject(myInterceptor);
                dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
            })
    },



    updateProfileAttempt({ dispatch }, user) {
        let loadingData = {
            isLoading: true,
            label: 'notifications.updating'
        }
        dispatch('root/setLoading', loadingData, { root: true })


        if (user.image) {
            dispatch('updateAvatar', user);
        } else {
            dispatch('updateProfile', user);
        }
    },

    updateAvatar({ dispatch }, user) {
        let loadingData = {
            isLoading: true,
            label: 'notifications.updating'
        }
        dispatch('root/setLoading', loadingData, { root: true })
        let updateImageData = {
            image: user.image
        }
        api.updateAvatar(updateImageData)
            .then(response => {
                let res = response.data
                actionToast.show(res && res.description ?
                    res.description : "Failed to update profile photo", { position: 'top' })
                if (res && res.status == 0) {
                    dispatch('updateProfile', user)
                } else {
                    dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
                }
            })
            .catch(err => {
                console.log(err)
                actionToast.show("Failed to update profile photo", { position: 'top' })
                dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
            })
    },

    updateProfile({ dispatch }, user) {
        let loadingData = {
            isLoading: true,
            label: 'notifications.updating'
        }
        dispatch('root/setLoading', loadingData, { root: true })
        let data = {
            user: user
        }
        api.updateProfile(data)
            .then(response => {
                let res = response.data;
                actionToast.show(res && res.description ?
                    res.description : "Failed to update profile", { position: 'top' })

                if (res && res.status == 0) {
                    dispatch('getProfileAttempt');
                } else {
                    dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
                }
            })
            .catch(err => {
                console.log(err)
                actionToast.show("Failed to update profile", { position: 'top' })
                dispatch('root/setLoading', { isLoading: false, label: '' }, { root: true })
            })
    },
    
    logout({ commit }) {
        commit('logout')
        router.replace({
            path: "/login"
        });
    }
}
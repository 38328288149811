import api from "../../api";
let actionToast;
// let actionRouter;
export default {
    setToastAndRouter({ dispatch }, payload) {
        dispatch
        actionToast = payload.toast;
        // actionRouter = payload.router;
    },
    async getPopVideos({ commit }) {
       try {
            const response = await api.getPopVideos();
            let res = response.data;
            if (res && res.status == 0) {
                commit('setPopVideos', res.popular_video);
            }
        } catch (err) {
            console.log(err);
        }
    },
    getVideos({ commit, dispatch, rootGetters }) {
        dispatch('root/setLoading',
            { isLoading: rootGetters['main/videos'].length === 0, label: 'notifications.updating' },
            { root: true })
        api.getPopVideos()
            .then(response => {
                let res = response.data
                if (res && res.status == 0) {
                    commit('setVideos', res.popular_video)
                }
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
            .catch(err => {
                console.log(err)
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
    },

    async getEmoticons({ commit , rootGetters}) {
       try {
            const response = await api.getEmoticons(rootGetters['auth/language']);
            let res = response.data;
            if (res && res.status == 0) {
                commit('setEmoticons', res.emotions);
            }
        } catch (err) {
            console.log(err);
        }
    },
    async getWeeklyChallenge({ commit, rootGetters}) {
       try {
            const response = await api.getWeeklyChallenge(rootGetters['auth/language']);
            let res = response.data;
            if (res && res.status == 0) {
                commit('setWeeklyChallenge', res);
            }
        } catch (err) {
            console.log(err);
        }
    },
    getEmotionsByFilterAttempt({ commit, dispatch, rootGetters }, filter) {
        dispatch('root/setLoading',
            { isLoading: rootGetters['main/emotionsByFilter'][filter].length === 0, label: 'notifications.updating' },
            { root: true })

        api.getEmotionsByFilter(filter)
            .then(response => {
                let res = response.data
                if (res) {
                    commit('setEmotionsByFilter', { key: filter, value: res })
                }
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
            .catch(err => {
                console.log(err)
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
    },
    addEmotionAttempt({ dispatch }, id) {
        let data = {
            eid: id
        }
        dispatch('root/setLoading',
            { isLoading: true, label: 'notifications.updating' },
            { root: true })
        api.addEmotion(data)
            .then(response => {
                let res = response.data
                if(res.status !== 0) 
                    actionToast.show('Server Error', { position: "top" });
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
            .catch(err => {
                actionToast.show('Server Error', { position: "top" });
                console.log(err)
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
    },
    getDailyNoteAttempt({ commit, dispatch, rootGetters }) {
        dispatch('root/setLoading',
            { isLoading: rootGetters['main/dailyNote'].length === 0, label: 'notifications.updating' },
            { root: true })
        api.getDailyNote(rootGetters['auth/language'])
            .then(response => {
                let res = response.data
                if (res && res.status == 0)
                    commit('setDailyNote', res.data)
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
            .catch(err => {
                console.log(err)
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
    },
    getDailyPhraseAttempt({ commit, dispatch, rootGetters }) {
        dispatch('root/setLoading',
            { isLoading: rootGetters['main/dailyPhrase'].length === 0, label: 'notifications.updating' },
            { root: true })
        api.getDailyPhrase(rootGetters['auth/language'])
            .then(response => {
                let res = response.data
                if (res && res.status == 0)
                    commit('setDailyPhrase', res.phrase.text)
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
            .catch(err => {
                console.log(err)
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
    },
    addNoteAttempt({ dispatch }, entry) {

        let data = {
            entry
        }
        dispatch('root/setLoading',
            { isLoading: true, label: 'notifications.updating' },
            { root: true })
        api.addNote(data)
            .then(response => {
                let res = response.data
                actionToast.show(res && res.description ? res.description : "Server Error", { position: 'top' })
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
            .catch(err => {
                console.log(err)
                actionToast.show("Server Error", { position: 'top' })
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
    },
    addDiaryAttempt({ dispatch }, entry) {
        let data = {
            entry
        }
        dispatch('root/setLoading',
            { isLoading: true, label: 'notifications.updating' },
            { root: true })
        api.addDiary(data)
            .then(response => {
                let res = response.data
                actionToast.show(res && res.description ? res.description : "Server Error", { position: 'top' })
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
            .catch(err => {
                console.log(err)
                actionToast.show("Server Error", { position: 'top' })
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
    },
    getNotesAttempt({ commit, dispatch }) {
        dispatch('root/setLoading',
            { isLoading: false, label: 'notifications.updating' },
            { root: true })

        api.getNotes()
            .then((response) => {
                let res = response.data
                if (res && res.notes) {
                    commit('setNotes', res.notes);
                }
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
            .catch(err => {
                console.log(err)
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
    },
    getDiariesAttempt({ commit, dispatch }) {
        dispatch('root/setLoading',
            { isLoading: true, label: 'notifications.updating' },
            { root: true })

        api.getDiaries()
            .then((response) => {
                let res = response.data
                if (res && res.diary) {
                    commit('setDiaries', res.diary);
                }
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
            .catch(err => {
                console.log(err)
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
    },
    getForumsAttempt({ commit, dispatch, rootGetters }) {
        dispatch('root/setLoading',
            { isLoading: rootGetters['main/forums'].length === 0, label: 'notifications.updating' },
            { root: true })
        api.getForums(rootGetters['auth/language'])
            .then(response => {
                let res = response.data;
                if (res && res.status == 0) {
                    commit('setForums', res.comunity)
                }
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
            .catch(err => {
                console.log(err)

                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
    },
    replyWeeklyChallengeAttempt({ dispatch, getters }, body) {
        dispatch('root/setLoading',
            { isLoading: true, label: 'notifications.updating' },
            { root: true })
        let data = {
            wid: getters.weeklyChallenge.id,
            html_text: body.entry,
            image: body.image
        }
        api.weeklychallengereply(data)
            .then(response => {
                let res = response.data;
                actionToast.show(res && res.description ? res.description : "Server Error", { position: 'top' })
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
            .catch(err => {
                console.log(err)
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
    },

    getCountries({ commit, dispatch }) {

        dispatch('root/setLoading',
            { isLoading: true, label: 'notifications.updating' },
            { root: true })
        api.getCountries()
            .then((response) => {
                let res = response.data
                if (res && res.status == 0)
                    commit('setCountries', res.countries);
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
            .catch(() => {
                commit('setCountries', [])
                dispatch('root/setLoading',
                    { isLoading: false, label: '' },
                    { root: true })
            })
    },
}
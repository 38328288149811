import rootActions from "../actions/root";
import rootMutations from "../mutations/root";

const state = () => ({
    isLoading: false,
    label: '',
})

const getters = {
    isLoading: (state) => {
        return state.isLoading;
    }
}

export default {
    namespaced: true,
    name: "root",
    state,
    getters,
    actions: rootActions,
    mutations: rootMutations
}

if (navigator.webdriver) {
    window.localStorage.clear();
}

export default {
    setPopVideos(state, data) {
        state.popVideos = data
    },
    setVideos(state, videos) {
        state.videos = videos
    },
    setEmoticons(state, emoticons) {
        state.emoticons = emoticons
    },
    setWeeklyChallenge(state, weeklyChallenge) {
        state.weeklyChallenge = weeklyChallenge
    },
    setEmotionsByFilter(state, payload) {
        var { key, value } = payload;
        state.emotions[key] = value
    },
    setDailyNote(state, note) {
        state.dailyNote = note
    },
    setDailyPhrase(state, phrase) {
        state.dailyPhrase = phrase
    },
    setNotes(state, notes) {
        state.notes = notes;
    },
    setDiaries(state, diaries) {
        state.diaries = diaries;
    },
    setForums(state, forums) {
        state.forums = forums;
    },
    setCountries(state, data) {
        let array = []
        for (let i = 0; i < data.length; i++) {
            let item = data[i]

            let customData = {
                label: item.name,
                value: item.code,
                code: item.language_code
            }
            array.push(customData)
        }

        state.countries = array;
    },
}
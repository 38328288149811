
if (navigator.webdriver) {
    window.localStorage.clear();
}

export default {
    setLoading(state, data) {
        state.isLoading = data.isLoading;
        state.label = data.label;
    },
}
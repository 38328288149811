import mainActions from '../actions/main'
import mainMutations from '../mutations/main'

const state = () => ({
    popVideos: [],
    videos: [],
    weeklyChallenge: {},
    notes: [],
    diaries: [],
    emotions: {
        daily: [],
        weekly: [],
        monthly: []
    },
    dailyPhrase: ". . .",
    dailyNote: [],
    interests: [],
    countries: [],
    emoticons: [],
    forums: []
})


const getters = {
    popVideos: (state) => {
        return state.popVideos;
    },
    videos: (state) => {
        return state.videos;
    },
    weeklyChallenge: (state) => {
        return state.weeklyChallenge;
    },
    notes: (state) => {
        return state.notes;
    },
    diaries: (state) => {
        return state.diaries;
    },
    emotionsByFilter: (state) => {
        return state.emotions;
    },
    dailyPhrase: (state) => {
        return state.dailyPhrase;
    },
    dailyNote: (state) => {
        return state.dailyNote;
    },
    interests: (state) => {
        return state.interests;
    },
    countries: (state) => {
        return state.countries;
    },
    emoticons: (state) => {
        return state.emoticons;
    },
    forums: (state) => {
        return state.forums;
    },
}

export default {
    namespaced: true,
    name: "main",
    state,
    getters,
    actions: mainActions,
    mutations: mainMutations
}
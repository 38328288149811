import { createApp } from "vue";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEyeSlash, faEye, faPlus, faSearch, faQuoteLeft, faMinus } from '@fortawesome/free-solid-svg-icons'
import { faFontAwesome, faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Toaster from '@meforma/vue-toaster';
import { setupI18n } from './i18n';
import App from './App.vue';
import router from "./router";
import store from "./store";
import './assets/css/styles.scss';
import en from './assets/locales/en.json';
import es from './assets/locales/es.json';
import GAuth from 'vue3-google-oauth2'

library.add(faFontAwesome)
library.add(faPlus)
library.add(faMinus)
library.add(faEye)
library.add(faGoogle)
library.add(faFacebook)
library.add(faEyeSlash)
library.add(faSearch)
library.add(faQuoteLeft)
const i18n = setupI18n({
    globalInjection: true,
    legacy: false,
    locale: 'es',
    fallbackLocale: 'es',
    messages: {
        en,
        es
    }
})
const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router);
app.use(store);
app.use(Toaster);
app.use(i18n);

let gauthClientId = "789198779342-qras924kr4l6rg3i5knfbjgmflvl5gsj.apps.googleusercontent.com";
app.use(GAuth, { clientId: gauthClientId, scope: 'email', prompt: 'consent', fetch_basic_profile: false })
app.mount('#app')

import authActions from '../actions/auth'
import authMutations from '../mutations/auth'

const state = () => ({
    token: '',
    name: '',
    email: '',
    mobile: '',
    country: '',
    city: '',
    birthday: '',
    interests: [],
    gender: '',
    image: '',
    points: 0,
    language: ''
})


const getters = {
    token: (state) => {
        return state.token;
    },
    name: (state) => {
        return state.name;
    },
    email: (state) => {
        return state.email;
    },
    mobile: (state) => {
        return state.mobile;
    },
    country: (state) => {
        return state.country;
    },
    language: (state) => {
        return state.language;
    },
    city: (state) => {
        return state.city;
    },
    birthday: (state) => {
        return state.birthday;
    },
    interests: (state) => {
        return state.interests;
    },
    gender: (state) => {
        return state.gender;
    },
    image: (state) => {
        return state.image;
    },
    points: (state) => {
        return state.points;
    }
}

export default {
    namespaced: true,
    name: "auth",
    state,
    getters,
    actions: authActions,
    mutations: authMutations
}
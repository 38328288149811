export const loadStore = key => {
  try {
    let serializedState = localStorage.getItem(key);
    if (
      serializedState === null ||
      !serializedState ||
      serializedState === undefined
    ) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return {};
  }
};


<template>
  <!-- <div class="dropDownMenuContainer">
    <drop-down-menu
      :config="config"
      @setSelectedOption="setNewSelectedOption($event)"
    />
  </div> -->
  <router-view id="spa-content-wrapper" />
  <div class="loadingMask" v-if="isLoading">
    <div class="loader" />
    <p class="label">{{ $t(label) }}</p>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapState } from "vuex";
import { useI18n } from "vue-i18n";
import { SUPPORT_LOCALES } from "./i18n";
import { loadStore } from "./utils/configureStorage";
// import DropDownMenu from "./components/DropDownMenu.vue";
export default {
  name: "App",
  components: {
    // DropDownMenu,
  },
  data() {
    let locale = (loadStore("besukha").auth || {}).language || "es";
    console.log("data------------", locale);
    return {
      config: {
        options: [
          {
            label: "En",
            value: "en",
          },
          {
            label: "Es",
            value: "es",
          },
        ],
        placeholder: locale == "en" ? "En" : "Es",
        backgroundColor: "#cde4f5",
        textColor: "black",
        borderRadius: "1.5em",
        border: "1px solid gray",
        width: 80,
      },
    };
  },
  computed: mapState({
    isLoading: (state) => state.root.isLoading,
    label: (state) => state.root.label,
  }),
  mounted() {
    let locale = (loadStore("besukha").auth || {}).language || "es";
    console.log("mounted------------", locale);
    this.$root.$i18n.locale = locale;
    let payload = {
      toast: this.$toast,
      router: this.$router,
      i18n: this.$root.$i18n
    };
    this.$store.dispatch("auth/setToastAndRouter", payload);
    this.$store.dispatch("main/setToastAndRouter", payload);
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });
    const currentLocale = ref(locale.value);
    return { t, locale, currentLocale, supportLocales: SUPPORT_LOCALES };
  },
  methods: {
    setNewSelectedOption(selectedOption) {
      this.config.placeholder = selectedOption.label;
      let locale = selectedOption.value.toLowerCase();
      this.$root.$i18n.locale = locale;
    },
  },
};
</script>
<style scoped>
.loadingMask {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-around;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.dropDownMenuContainer {
  position: absolute;
  top: 20px;
  right: 20px;
}
.label {
  font-size: 18px;
  color: white;
  font-weight: bold;
}
.loader {
  border: 5px solid gray;
  border-radius: 50%;
  border-top: 5px solid #f3f3f3;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
import axios from 'axios'
import moment from 'moment'
import router from './router';

const base_url = process.env.VUE_APP_API;

axios.interceptors.response.use((res) => {
    if (res.status == 99) {
        localStorage.clear();
        router.replace({
            path: "/login"
          });
    }
    return res
}, (err) => {
    return err
});

export default {

    registerAttempt(user) {
        const formdata = new FormData();

        formdata.append('name', user.name);
        formdata.append('email', user.email);
        formdata.append('gender', user.gender);
        formdata.append('birthday', moment(user.birthday).format("YYYY-MM-DD"));
        formdata.append('password', user.password);

        return axios.post(`${base_url}/api/profile/create`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'BeSukha-Magic-Token': '72fcc8ae-f749-11ea-adc1-0242ac120002'
            },
        })
    },

    loginAttempt(user) {
        const formdata = new FormData();

        formdata.append('email', user.email);
        if (user.password)
            formdata.append('password', user.password);
        if (user.auth_token)
            formdata.append('auth_token', user.auth_token);
        if (user.auth_type)
            formdata.append('auth_type', user.auth_type);


        // return axios.post(`${base_url}/api/profile/recover`, formdata, {
        return axios.post(`${base_url}/api/jb/profile/login`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    resetPassword(email) {
        const formdata = new FormData();
        formdata.append('email', email);
        return axios.post(`${base_url}/api/profile/passwordreset`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    getProfileAttempt() {
        // return axios.get(`${base_url}/api/profile/${data.id}/${data.token}`);
        return axios.get(`${base_url}/api/jb/profile/data`);
    },

    getCountries() {
        return axios.get(`${base_url}/api/jb/system/countries`)
        // return axios.get(`${base_url}/api/system/countries`)
    },

    getPopVideos() {
        // return axios.get(`${base_url}/api/system/popularvideo`)
        return axios.get(`${base_url}/api/jb/system/popularvideo`)
    },
    getEmoticons(locale) {
        // return axios.get(`${base_url}/api/system/emotions`)
        return axios.get(`${base_url}/api/jb/system/emotions/${locale}`)
    },
    getWeeklyChallenge(locale) {
        // return axios.get(`${base_url}/api/system/weeklychallenge`)
        return axios.get(`${base_url}/api/jb/system/weeklychallenge/${locale}`)
    },
    getEmotionsByFilter(filter) {
        // return axios.get(`${base_url}/api/profile/emotions/${data.id}/${data.token}/${data.opt}`)
        return axios.get(`${base_url}/api/jb/profile/emotions/${filter}`)
    },
    addEmotion(data) {
        var formdata = new FormData();
        // formdata.append('token', data.token);
        // formdata.append('uid', data.uid);
        // formdata.append('eid', data.eid);
        formdata.append('user_id', data.uid);
        formdata.append('emotion_id', data.eid);
        // return axios.post(`${base_url}/api/profile/update/emotion`, formdata, {
        return axios.post(`${base_url}/api/jb/profile/update/emotion`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    getDailyNote(locale) {
        // return axios.get(`${base_url}/api/system/dailynote`);
        return axios.get(`${base_url}/api/jb/system/dailynote/${locale}`);
    },
    getDailyPhrase(locale) {
        // return axios.get(`${base_url}/api/system/dailyphrase`)
        return axios.get(`${base_url}/api/jb/system/dailyphrase/${locale}`)
    },
    addNote(data) {
        var formdata = new FormData();
        // formdata.append('token', data.token);
        formdata.append('uid', data.uid);
        formdata.append('entry', data.entry);
        // return axios.post(`${base_url}/api/profile/notes/update`, formdata, {
        return axios.post(`${base_url}/api/jb/profile/notes/update`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })

    },
    addDiary(data) {
        var formdata = new FormData();
        // formdata.append('token', data.token);
        // formdata.append('uid', data.uid);
        // formdata.append('entry', data.entry);
        formdata.append('entry', data.entry);
        // return axios.post(`${base_url}/api/profile/update/diary`, formdata, {
        return axios.post(`${base_url}/api/jb/profile/update/diary`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })

    },
    getNotes() {
        // return axios.get(`${base_url}/api/profile/notes/${data.id}/${data.token}`);
        return axios.get(`${base_url}/api/jb/profile/notes`);
    },
    getDiaries() {
        // return axios.get(`${base_url}/api/profile/diary/${data.id}/${data.token}`);
        return axios.get(`${base_url}/api/jb/profile/diary`);
    },
    getForums(language) {
        // return axios.get(`${base_url}/api/system/comunity`);
        return axios.get(`${base_url}/api/jb/system/community/${language}`);
    },
    weeklychallengereply(data) {
        var formdata = new FormData();
        // formdata.append('token', data.token);
        formdata.append('user_id', data.uid);
        formdata.append('weekly_challenge_id', data.wid);
        formdata.append('html_text', data.html_text);
        formdata.append('picture', data.image);
        // return axios.post(`${base_url}/api/profile/weeklychallengereply`, formdata, {
        return axios.post(`${base_url}/api/jb/profile/weeklychallengereply`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    updateProfile(data) {
        var formdata = new FormData();
       // formdata.append('token', data.token);
       // formdata.append('user_id', data.uid);
        formdata.append('name', data.user.name);
        formdata.append('city', data.user.city);
        formdata.append('country', data.user.country);
        formdata.append('gender', data.user.gender);
        formdata.append('mobile', data.user.mobile);
        formdata.append('language', data.user.language);
        // formdata.append('interests', JSON.stringify(data.user.interests));
        formdata.append('birthday', moment(data.user.birthday).format("YYYY-MM-DD"));

        // return axios.post(`${base_url}/api/profile/update`, formdata, {
        return axios.post(`${base_url}/api/jb/profile/update`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    updateAvatar(data) {
        var formdata = new FormData();
        formdata.append('token', data.token);
        formdata.append('uid', data.uid);
        formdata.append('image', data.image);

        // return axios.post(`${base_url}/api/profile/update/image`, formdata, {
        return axios.post(`${base_url}/api/jb/profile/update/image`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
}
import { createRouter, createWebHistory } from "vue-router"
/**
 * Signature of all route guards:
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 *
 * See http://router.vuejs.org/en/advanced/navigation-guards.html
 * for more details.
 */

// function requireAuth(to, from, next) {
//     if (!auth.loggedIn()) {
//         next({
//             path: '/login',
//             query: { redirect: to.fullPath }
//         })
//     } else {
//         next()
//     }
// }

const routes = [
  { path: "", redirect: "/landing" },
  { path: "/landing", component: () => import("./pages/LandingPage") },
  { path: "/login", component: () => import("./pages/LoginPage") },
  {
    path: "/register",
    component: () => import("./pages/RegisterPage"),
    beforeEnter(to, from, next) {
      if (process.env.VUE_APP_ENABLE_REGISTER === "false") next("/login")
      else next()
    },
  },
  {
    path: "/forgotPassword",
    component: () => import("./pages/ForgotPasswordPage"),
  },
  { path: "/dashboard", component: () => import("./pages/DashboardPage") },
  { path: "/profile", component: () => import("./pages/ProfilePage") },
  { path: "/myStory", component: () => import("./pages/MyStoryPage") },
  { path: "/dayNote", component: () => import("./pages/DayNotePage") },
  { path: "/forum", component: () => import("./pages/ForumPage") },
  { path: "/calc", component: () => import("./pages/CalcPage") },
  { path: "/personal", component: () => import("./pages/PersonalPage") },
  { path: "/myNotes", component: () => import("./pages/MyNotesPage") },
  { path: "/videos", component: () => import("./pages/VideosPage") },
  {
    path: "/weeklyChallenge",
    component: () => import("./pages/WeeklyChallengePage"),
  },
  {
    path: "/pageNotFound",
    component: () => import("./pages/PageNotFoundPage"),
  },
  { path: "/:pathMatch(.*)*", redirect: "/pageNotFound" },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

/* eslint-disable no-new */
export default router
